import { motion } from "framer-motion";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";

import { setGlobalLoading } from "../../redux/features/globalLoadingSlice";
import { routesGen } from "../../routes/routes";

import uiConfigs from "../../configs/ui.configs";

import CircularRate from "./CircularRate";

import tmdbConfigs from "../../api/configs/tmdb.configs";
import genreApi from "../../api/modules/genre.api";
import mediaApi from "../../api/modules/media.api";

const HeroSlide = ({ mediaType, mediaCategory }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [movies, setMovies] = useState([]);
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    const getMedias = async () => {
      const { response, err } = await mediaApi.getList({
        mediaType,
        mediaCategory,
        page: 1,
      });

      if (response) setMovies(response.results);
      if (err) toast.error(err.message);
      dispatch(setGlobalLoading(false));
    };

    const getGenres = async () => {
      dispatch(setGlobalLoading(true));
      const { response, err } = await genreApi.getList({ mediaType });

      if (response) {
        setGenres(response.genres);
        getMedias();
      }
      if (err) {
        toast.error(err.message);
        setGlobalLoading(false);
      }
    };

    getGenres();
  }, [mediaType, mediaCategory, dispatch]);

  return (
    <Box
      sx={{
        position: "relative",
        color: "primary.contrastText",
        "&::before": {
          content: '""',
          width: "100%",
          height: "30%",
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 2,
          pointerEvents: "none",
          ...uiConfigs.style.gradientBgImage[theme.palette.mode],
        },
      }}
    >
      <Swiper
        grabCursor={true}
        loop={true}
        modules={[Autoplay]} // Importa Autoplay aquí
        style={{ width: "100%", height: "max-content" }}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
      >
        {movies.map((movie, index) => (
          <SwiperSlide key={index}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <Box
                sx={{
                  paddingTop: {
                    xs: "130%",
                    sm: "80%",
                    md: "60%",
                    lg: "45%",
                  },
                  backgroundPosition: "top",
                  backgroundSize: "cover",
                  backgroundImage: `url(${tmdbConfigs.backdropPath(
                    movie.backdrop_path || movie.poster_path
                  )})`,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  ...uiConfigs.style.horizontalGradientBgImage[
                    theme.palette.mode
                  ],
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  paddingX: { sm: "10px", md: "5rem", lg: "10rem" },
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    paddingX: "30px",
                    color: "text.primary",
                    width: { sm: "unset", md: "30%", lg: "40%" },
                  }}
                >
                  <Stack spacing={4} direction="column">
                    {/* title */}
                    <Typography
                      variant="h4"
                      fontSize={{ xs: "2rem", md: "2rem", lg: "4rem" }}
                      fontWeight="700"
                      sx={{
                        ...uiConfigs.style.typoLines(2, "left"),
                      }}
                    >
                      {movie.title || movie.name}
                    </Typography>
                    {/* title */}

                    <Stack direction="row" spacing={1} alignItems="center">
                      {/* rating */}
                      <CircularRate value={movie.vote_average} />
                      {/* rating */}

                      <Divider orientation="vertical" />
                      {movie.genre_ids.slice(0, 2).map((genreId) => (
                        <Chip
                          key={genreId} // Utilizamos el genreId como clave
                          label={
                            genres.find((genre) => genre.id === genreId)?.name // Usamos el operador de encadenamiento opcional para evitar errores si no se encuentra el género
                          }
                          sx={{
                            borderRadius: "16px", // Bordes redondeados
                            background:
                              "linear-gradient(to right, #a855f7, #00c6ff)", // Color degradado
                            color: "#FFFFFF", // Color de texto
                            fontWeight: "bold", // Texto en negrita
                            boxShadow:
                              "0 4px 6px rgba(168, 85, 247, 0.5), 0 1px 3px rgba(0, 198, 255, 0.5)",
                            transition: "background-color 0.3s ease",
                            fontSize: "0.9rem", // Tamaño de fuente
                            "&:hover": {
                              background:
                                "linear-gradient(to right, #00c6ff, #a855f7)", // Cambio de color degradado al pasar el mouse
                            },
                            mr: 1, // Espacio a la derecha entre los chips
                            mb: 1, // Espacio debajo de los chips
                          }}
                        />
                      ))}
                      {/* genres */}
                    </Stack>

                    {/* overview */}
                    <Typography
                      variant="body1"
                      sx={{
                        ...uiConfigs.style.typoLines(3),
                      }}
                    >
                      {movie.overview}
                    </Typography>
                    {/* overview */}

                    {/* buttons */}
                    <Button
                      variant="contained"
                      size="large"
                      startIcon={<PlayArrowIcon />}
                      component={Link}
                      to={routesGen.mediaDetail(mediaType, movie.id)}
                      sx={{
                        width: "max-content",
                        borderRadius: 20,
                        backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        color: theme.palette.primary.contrastText,
                        boxShadow:
                          "0 4px 6px rgba(168, 85, 247, 0.5), 0 1px 3px rgba(0, 198, 255, 0.5)",
                        transition: "background-color 0.3s ease",
                        "&:hover": {
                          boxShadow:
                            "0 4px 6px rgba(168, 85, 247, 0.5), 0 1px 3px rgba(0, 198, 255, 0.5)",
                          transition: "background-color 0.3s ease",
                          backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
                        },
                        "&:focus": {
                          backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
                          boxShadow:
                            "0 4px 6px rgba(168, 85, 247, 0.5), 0 1px 3px rgba(0, 198, 255, 0.5)",
                          transition: "background-color 0.3s ease",
                        },
                      }}
                    >
                      Watch Now
                    </Button>

                    {/* buttons */}
                  </Stack>
                </Box>
              </Box>
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default HeroSlide;
