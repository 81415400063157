import { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import mediaApi from "../../api/modules/media.api";
import AutoSwiper from "./AutoSwiper";
import { toast } from "react-toastify";
import MediaItem from "./MediaItem";
import { CircularProgress, Typography, Box } from "@mui/material";

const MediaSlide = ({ mediaType, mediaCategory }) => {
  const [medias, setMedias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getMedias = async () => {
      setLoading(true);
      setError(null);

      const { response, err } = await mediaApi.getList({
        mediaType,
        mediaCategory,
        page: 1,
      });

      if (response) {
        setMedias(response.results);
      } else {
        setError(err);
        toast.error(err.message);
      }

      setLoading(false);
    };

    getMedias();
  }, [mediaType, mediaCategory]);

  const handleHover = (index) => {
    // Implement hover effect here
  };

  const handleTap = (index) => {
    // Implement tap effect here
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center">
        Failed to load media. Please try again later.
      </Typography>
    );
  }

  return (
    <AutoSwiper>
      {medias.map((media, index) => (
        <SwiperSlide key={index} style={{ margin: "0 8px" }}>
          <motion.div
            key={index}
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
            whileTap={{
              scale: 0.95,
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            onMouseEnter={() => handleHover(index)}
            onMouseLeave={() => handleHover(index)}
            onTap={() => handleTap(index)}
          >
            <MediaItem media={media} mediaType={mediaType} />
          </motion.div>
        </SwiperSlide>
      ))}
    </AutoSwiper>
  );
};

export default MediaSlide;
