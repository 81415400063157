import { useState, useEffect } from "react";
import { CiPlay1 } from "react-icons/ci";
import { Link } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CircularRate from "./CircularRate";
import { useSelector } from "react-redux";
import favoriteUtils from "../../utils/favorite.utils";
import tmdbConfigs from "../../api/configs/tmdb.configs";
import uiConfigs from "../../configs/ui.configs";
import { routesGen } from "../../routes/routes";

const NeonPlayIcon = () => {
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <CiPlay1
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        color: isHovered ? "#fda4af" : "#fff", // Cambia el color a blanco cuando está en estado de hover, de lo contrario, usa el color neon
        fontSize: "3.5em", // Tamaño del icono
        filter:
          "drop-shadow(0 0 5px #fff) drop-shadow(0 0 10px #d946ef) drop-shadow(0 0 15px #d946ef) drop-shadow(0 0 20px #d946ef) drop-shadow(0 0 35px #d946ef) drop-shadow(0 0 40px #d946ef)", // Efecto de estilo neon
      }}
    />
  );
};

const MediaItem = ({ media, mediaType }) => {
  const { listFavorites } = useSelector((state) => state.user);

  const [title, setTitle] = useState("");
  const [posterPath, setPosterPath] = useState("");
  const [releaseDate, setReleaseDate] = useState(null);
  const [rate, setRate] = useState(null);

  useEffect(() => {
    setTitle(media.title || media.name || media.mediaTitle);
    setPosterPath(
      tmdbConfigs.posterPath(
        media.poster_path ||
          media.backdrop_path ||
          media.mediaPoster ||
          media.profile_path
      )
    );

    if (mediaType === tmdbConfigs.mediaType.movie) {
      setReleaseDate(media.release_date && media.release_date.split("-")[0]);
    } else {
      setReleaseDate(
        media.first_air_date && media.first_air_date.split("-")[0]
      );
    }

    setRate(media.vote_average || media.mediaRate);
  }, [media, mediaType]);

  return (
    <Link
      to={
        mediaType !== "people"
          ? routesGen.mediaDetail(mediaType, media.mediaId || media.id)
          : routesGen.person(media.id)
      }
    >
      <Box
        sx={{
          ...uiConfigs.style.backgroundImage(posterPath),
          borderRadius: "10px",
          paddingTop: "160%",
          "&:hover .media-info": { opacity: 1, bottom: 0 },
          "&:hover .media-back-drop, &:hover .media-play-btn": { opacity: 1 },
          color: "primary.contrastText",
        }}
      >
        {/* movie or tv item */}
        {mediaType !== "people" && (
          <>
            {favoriteUtils.check({ listFavorites, mediaId: media.id }) && (
              <FavoriteIcon
                color="primary"
                sx={{
                  position: "absolute",
                  top: 2,
                  right: 2,
                  fontSize: "2rem",
                }}
              />
            )}
            <Box
              className="media-back-drop"
              sx={{
                opacity: { xs: 1, md: 0 },
                transition: "opacity 0.3s ease",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                backgroundImage:
                  "linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))",
              }}
            />
            <Button
              className="media-play-btn"
              startIcon={<NeonPlayIcon />}
              sx={{
                display: { xs: "none", md: "flex" },
                opacity: 0,
                transition: "opacity 0.3s ease",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60px", // Tamaño del botón
                height: "60px",
                border: "none", // Sin borde
                boxShadow: "none", // Sin sombra
                background: "none", // Sin color de fondo
              }}
            />
            <Box
              className="media-info"
              sx={{
                transition: "all 0.3s ease",
                opacity: { xs: 1, md: 0 },
                position: "absolute",
                bottom: { xs: 0, md: "-20px" },
                width: "100%",
                height: "max-content",

                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: { xs: "10px", md: "20px" },
              }}
            >
              <Stack spacing={2}>
                {rate && <CircularRate value={rate} />}

                <Typography
                  variant="body1"
                  fontWeight="bold"
                  fontSize="1.2rem"
                  textAlign="left"
                >
                  {title}
                </Typography>

                <Typography fontSize="1rem" color="#666" textAlign="left">
                  Release Date: {releaseDate}
                </Typography>
              </Stack>
            </Box>
          </>
        )}
        {/* movie or tv item */}

        {/* people */}
        {mediaType === "people" && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              padding: "10px",
              backgroundColor: "rgba(0,0,0,0.6)",
              color: "#fff",
              bottom: 0,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <Typography variant="body1" textAlign="left" fontWeight="bold">
              {media.name}
            </Typography>
          </Box>
        )}
        {/* people */}
      </Box>
    </Link>
  );
};

export default MediaItem;
