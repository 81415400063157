import { useTheme } from "@mui/material";
import { logo } from "../../assets";

const Logo = () => {
  const theme = useTheme();

  return (
    <div>
      <img
        src={logo}
        alt="Logo de AmyDoramas"
        style={{ width: "180px", height: "auto" }}
      />
    </div>
  );
};

export default Logo;
